@import "src/assets/mixins";
@import "src/assets/variables";

.wrap{
  position: relative;
}
.container {
  position: relative;
  top:3px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.count{
  width: 25px;
  height: 19px;
  border-radius: 50%;
  background-color: $error;
  @include reg12mobSemi($white);  
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1px;
  left: 18px;
  user-select: none;
}

.popup_container{
  width: 520px; 
  overflow: hidden;
  border: 1px solid $primary;
  border-radius: 10px;
  position: absolute;
  top:40px;
  right: 0px;
  z-index: 20;
  background-color: $white;
  cursor: default;
  padding-right: 2px;
  @media screen and (max-width:600px){
    width: 300px;
  }
  @media screen and (max-width:400px){
    right: -40px;
  }
  @media screen and (max-width:340px){
    right: -50px;
  }
  
  .popup_header{
    height: 44px;
    border-bottom: 1px solid rgba($primary, 0.2);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background-color: $white;
    @include b3($text-primary);
    svg{
      cursor: pointer;
    }
  }
  .popup_list{
    background-color: $color-26;
    padding: 16px 20px;
    overflow-y: auto;
    box-shadow: $shadow-1;
    display: flex;
    flex-direction: column;
    gap:16px;
    scrollbar-width: thin; 
    scrollbar-color: $primary $color-26;
    
    max-height: 360px;
    &::-webkit-scrollbar-thumb{
      border-radius: 6px ; /* округлось бегунка */
      
    } 
    
  .item_wrap{
    display: flex;
    flex-direction: column;
    gap:8px;
    border-bottom: 1px solid rgba($primary, 0.2);
    padding-bottom: 16px;
  }
  .item_name {
    @include b3($text-primary);
  }
  .item_status{
    width: 150px;
    height: 25px;
    @include reg12mobSemi;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
  
    &.await{
      background-color: $color-19;
      color: $error;
    }
    &.full_paid{
      background-color: $color-20;
      color: $color-21;
    }
    &.part_paid {
      background-color: $color-22;
      color:$color-23;
    }
    &.not_paid {
      background-color: $color-24;
      color:$color-25;
    }
    &.new {
      background-color: $color-32;
      color: $text-primary;
    }
  }
  .item_main{
    @include h4mob($text-primary);
  }
  .item_create{
    @include h4mob($primary);
    font-size: 12px;
  }
  }
}

